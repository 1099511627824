// _variables/design
//    CAMP-CMS
//    Base Color
$primary-accent-color: #e95513;
$secondary-accent-color: #E95613;

//    Background
$background-body-default: #f8fafc;
$background-sidebar-default: #000;
$background-page-header: #FFF7D8;
$background-page-footer: #DFDFDF;
$background-page-form: #DFDFDF;
$background-form-alert: #fdd;


//    Colors
$color-text-base: #595757;

$color-link-normal: #f29600;
$color-link-hover: #e95513;
$color-link-active: #e95513;
$color-link-visited: #e95513;

$color-impress-1: #e95513;
$color-impress-2: #f29600;
$color-impress-3: #FFF7D8;

$color-form-focus: #fffbef;
$color-alert: #900;
$color-inactive: #f2cc99;
$color-form: #DFDFDF;
$color-form-focus: #fffbef;
$color-alert: #900;
$color-inactive: #f2cc99;

//    Buttons
$background-button-normal: $color-link-normal;
$background-button-hover: #bd3f08;
$background-button-impress1-normal: $color-impress-1;
$color-button-normal: #fff;

//    Font
$font-size-xx-small: 0.7rem;
$font-size-x-small: 0.8rem;
$font-size-small: 1rem;
$font-size-large: 1.1rem;
$font-size-x-large: 1.5rem;
$font-size-xx-large: 2.2rem;

$font-size-larger-factor: 1.3em;

//    Layouts
$layout-contents-width-pc: 1000px;